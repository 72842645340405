<template>
    <section id="contact" class="contact-area bg-gray ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-6">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">Contact Us</h2>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between">

                    <div class="col-12">
                        <!-- Contact Box -->
                        <div class="contact-box text-center">
                            <!-- Contact Form -->
<!--                            <form id="contact-form" method="POST" action="/assets/php/mail.php">-->
                                <div class="row">
<!--                                    <div class="col-12">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" name="name" placeholder="Name" required="required">-->
<!--                                        </div>-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="email" class="form-control" name="email" placeholder="Email" required="required">-->
<!--                                        </div>-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-12">-->
<!--                                        <div class="form-group">-->
<!--                                            <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                    <div class="col-12">
                                      <a href="mailto:support@bricksee.app">
                                        <button type="submit" class="btn btn-lg btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send Message</button>
                                      </a>
                                    </div>
                                </div>
<!--                            </form>-->
                            <p class="form-message"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    
}
</script>

<style>

</style>